@import "functions/function";

*, *::before, *::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::placeholder {
  color: $c-main-lighten;
}

::selection {
  background-color: $c-primary;
  color: #fff;
}

.align-center {
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f-heading;
  font-weight: 700;
}
.d-none {
  display: none !important;
}

.flex-center {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

img,
button {
  user-select: none;
  -webkit-user-drag: none;
}

.text-center {
  text-align: center;
}

img {
  max-width: 100%;
}

.img-width {
  width: 100%;
}

body {
  font-family: $f-text;
  font-size: rem(16);
  min-width: 320px;
  position: relative;
  line-height: 1.5;
  overflow-x: hidden;
  color: $c-main;

}

body input,
body textarea {
  outline: none;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  border-color: red;
}

body input:required:valid,
body textarea:required:valid {
  border-color: green;
}

body.fix {
  overflow: hidden;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  stroke-width: 2;
}

b,
strong {
  font-weight: bold;
}
main{
  .section-spacer{
    &:first-child{
      padding-top: 150px;
      @include media($lg){
        padding-top: 100px;
      }
    }
  }
}
.form-input--h{
  display: none !important;
}
@import "media";
