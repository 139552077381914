@import "normalize.css/normalize";
@import "swiper/swiper-bundle";
// functions //
@import "functions/reset";
@import "functions/vars";
@import "functions/mixins";
@import "functions/fonts";
@import "functions/config";
@import "functions/function";
@import "functions/base";
@import "functions/tipography";
@import "functions/grid";

// modules //
@import "modules/buttons";
@import "modules/circles";
@import "modules/sliders";
@import "modules/selects";
@import "modules/svg-map";
@import "modules/dark-bg";
@import "modules/circle-loader";
@import "modules/subtitle";
@import "modules/breadcrumbs";
@import "modules/tabs";
@import "modules/modal";
@import "modules/last-word";
@import "modules/popup-form";

//blocks
@import "blocks/header";
@import "blocks/general-slide";
@import "blocks/nav";
@import "blocks/mobile-menu";
@import "blocks/footer";
@import "blocks/product";
@import "blocks/calculator";
@import "blocks/state";
@import "blocks/scheme";
@import "blocks/decision";
@import "blocks/company";
@import "blocks/advantages";
@import "blocks/map";
@import "blocks/selection";
@import "blocks/certificates";
@import "blocks/video";
@import "blocks/faq";
@import "blocks/params";
@import "blocks/filter";
@import "blocks/conditions";
@import "blocks/config";
@import "blocks/about";
@import "blocks/construction";
@import "blocks/cabin";
@import "blocks/order";
@import "blocks/individual";
@import "blocks/features";
@import "blocks/form";
@import "blocks/which";
@import "blocks/territory";
@import "blocks/experience";
@import "blocks/news";
@import "blocks/article";
@import "blocks/contact";
@import "blocks/work";
@import "blocks/price";
@import "blocks/delivery";
@import "blocks/error";
@import "blocks/scroll";

@import "media";

