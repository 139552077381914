.btn {
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: rem(140);
    font-family: $f-text;
    font-weight: 700;
    border: none;
    user-select: none;
    font-size: rem(16);
    line-height: 1.2;
    padding: rem(23) rem(40);
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: rem(36);
    overflow: hidden;
    text-align: center;
    color: $c-main;
    transition: $transition-primary;
    
    &--transparent{
        border: 0.125rem solid $c-primary;
        min-width: rem(136);
        padding: rem(21) rem(40);
        background: transparent;
        &:hover{
            background: $c-primary;
            color: $c-main;
        }
    }
    &--gradient{
        background: $gradient;
       // border: 2px solid transparent;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $c-primary;
            opacity: 0;
            z-index: -1;
            transition: $transition-primary;
        }
        &:hover{
            &:before{
                opacity: 1;
                color: $c-main;
            }
            color: $c-main;
        }
    }
  }
  