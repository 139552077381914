.product{
    margin-bottom: rem(60);
    display: none;
    &.active{
        display: flex;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .h2{
        display: flex;
        align-items: center;
        transition: $transition-primary;
        img{
            align-self: start;
        }
        .icon{
            font-size: rem(16);
            min-width: 16px;
            margin-left: rem(20);
        }
        &:hover{
            color: $c-primary;
        }
    }
}
.product__content{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include media($lg){
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.product__slider, .product_slider{
    @include media($lg){
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: rem(40);
    }
}
.product__info{
    flex: 0 0 calc(60% - 38px);
    margin-right: rem(38);
    @include media($sm){
        flex: 0 0 100%;
        margin-right: 0; 
    }
}
.product__desc{
    font-family: 'Inter';
    span{
        color: $c-main-lighten;
    }
}
.product__unwrap{
    font-weight: 700;
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: $transition-primary;
    .icon{
        margin-left: rem(8);
        transition: $transition-primary;
        font-size: rem(11);
        width: 11px;
        height: 6px;
    }
    &.active{
        .icon{
            transform: rotate(180deg);
        }
    }
}
.product__advantages{
    flex: 0 0 40%;
    @include media($sm){
        margin-top: rem(20);
        flex: 0 0 100%;
    }
}
.product__item{
    display: flex;
    align-items: center;
    padding: rem(16) rem(26);
    border-top: 1px solid $c-secondary-gray;
   
    line-height: 26px;
    &:first-child{
        border-top: 0; 
    }
    .icon{
        font-size: rem(32);
        color: $c-primary;
        min-width: 32px;
        margin-right: rem(20);
    }
}
.product__price{
    flex: 0 0 100%;
    padding: rem(33);
    background: #F6F6F7;
    margin-top: rem(30);
    @include media($lg){
        margin-bottom: rem(48);
    }
}
.product__row{
    display: flex;
    align-items: center;
   // flex-wrap: wrap;
    &:first-child{
        margin-bottom: rem(10);
    }
    p{
        margin-right: rem(32);
        color: $c-main-lighten;
        font-family: 'Inter';
        span{
            color: $c-main;
            font-weight: 700;
        }
        &:last-child{
            margin-bottom: 1rem;
        }
    }
    .btn{
        margin-right: rem(32);
    }
    .help{
        display: flex;
        align-items: center;
        color: $c-main-lighten;
        font-weight: 700;
        cursor: pointer;
        //position: relative;
        transition: $transition-primary;
        .icon{
            font-size: rem(24);
            margin-right: rem(14);
            min-width: 14px;
            color: $c-primary;
        }
       
        .help__submenu{
            z-index: 3;
            position: absolute;
            width: 96%;
            background: $c-white;
            left: 2%;
            top: 95%;
            transform: translateY(-10px);
            padding: rem(33) rem(43);
            transition: $transition-primary;
            opacity: 0;
            visibility: hidden;
            box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.05);

            .title{
                font-weight: 700;
                font-size: rem(16);
                line-height: rem(26);
                color: $c-main;
            }
            ul{
                li{
                    font-weight: 400;
                    padding-left: 10px;
                    color: $c-main;
                    span{
                        color: $c-main-lighten;
                    }
                    &:before{
                        content: '';
                        width: 6px;
                        position: absolute;
                        height: 6px;
                        background: $c-primary;
                        border-radius: 50%;
                        left: 0;
                        top: 12px;
                    }
                }
            }
            @include media(668px){
                top: 100%;
            }
            @include media(440px){
                .col-6{
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-bottom: rem(20);
                }
            }
        }
        &:hover{
            color: $c-main; 
            .help__submenu{
                opacity: 1;
                visibility: visible;
            }
        }
        @include media(668px){
            margin-top: rem(20);
        }
    }
    @include media($sm){
        flex-wrap: wrap;
     }
    @include media($xs){
       .btn{
            padding: rem(14) rem(20);
            margin-right: rem(14);
            &--transparent{
                padding: rem(12) rem(20);
            }
       }
    }
}
.product__full-info{
    .title{
        font-size: rem(24);
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            margin-right: 12px;
            font-weight: 300;
            span{
                color: $c-main-lighten;
            }
        }
    }
    @include media($sm){
        .col-5, .col-7{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: rem(20);
        }
    }
}

.sale{
    flex-wrap: wrap;
    align-items: start;
    margin-bottom: 0;
    .btn{
        margin-top: rem(10);
        @include media(650px){
            margin-top: rem(26);
        }
    }
}
.sale__price{
    margin-right: rem(32);
    flex: 1 1 100%;
    .sale__this{
        display: block;
        margin-bottom: rem(8);
        font-weight: 700;
        font-size: rem(36);
        line-height: rem(44);
        
    }
    @include media(650px){
        width: 100%;
    }
}
.sale__block{
    display: flex;
    align-items: center;
}