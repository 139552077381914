.experience{
    @include media($md){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.experience__imgBlock{
    position: relative;
    img{
        width: 100%;
    }
    @include media($md){
        margin-bottom: rem(48);
    }
}
.experience__info{
    position: absolute;
    left: 0;
    bottom: 6px;
    background: $c-white;
    padding: rem(16) rem(32);
    padding-left: 47px;
    .title{
        font-size: rem(24);
        line-height: rem(32);
        margin: 0;
    }
    .year{
        display: block;
        color: $c-primary;
        font-size: rem(48);
        line-height: rem(56);
    }
    @include media($md){
       bottom: 0;
    }
}
.experience__wrapper{
    display: flex;
    flex-wrap: wrap;
    margin: rem(40) 0;
}
.experience__item{
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 3rem);
    margin-right: rem(48);
    margin-bottom: rem(24);
    span{
        font-size: rem(16);
        line-height: rem(26);
    }
    .icon{
        font-size: rem(32);
        min-width: 32px;
        fill: $icon-fill;
        margin-right: rem(20);
    }
    @include media(390px){
        flex: 0 0 calc(50% - 1.5rem);
        margin-right: rem(24);
    }
    @include media(350px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.experience__additionaly{
    background: $c-block;
    padding: rem(24);
    span{
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(26);
    }
}