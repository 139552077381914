.error{
    height: 100vh;
    display: flex;
    flex-direction: column;
    @include flex-center();
    margin-top: rem(140);
    p{
        margin-top: rem(72);
        text-align: center;
    }
    @include media($lg){
        margin-top: rem(80);
    }
}