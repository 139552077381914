.calculator{
    background: $c-block;
    overflow: hidden;
    background-image: url('/assets/img/assets/line_white.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .otherDelivery {
        margin-top: rem(40);
        .name{
            text-align: end;
            color: #939393;
        }
        .calculator-inner__row{
            justify-content: end;
        }
    }
    .h2{
        color: $c-white;
        .mr-30{
            margin-right: rem(30);
        }
    }
    .calculator__sum{
        justify-content: end;
        flex-wrap: wrap;
    }
}
.calculator__header{
    display: flex;
    align-items: center;
    border-bottom: 1px solid  $c-secondary-gray;
    margin-bottom: rem(24);
    .first{
        flex: 0 0 15% !important;
    }
    .calculator__item{
        &:nth-child(1){
             flex: 0 0 15% !important;
         }
    }
    .calculator__item, span{
        flex: 0 0 20%;
        margin-right: rem(20);
        color: $c-main-lighten;
        margin-bottom: rem(24);
        &:nth-child(2){
            flex: 0 0 25% !important;
        }
        &:nth-child(3), &:nth-child(4){
            flex: 0 0 17.5% !important;
        }
        &:last-child{
            margin-right: 0;
            .icon{
                margin-left: 30px;
                cursor: pointer;
                transition: $transition-primary;
                color: $c-white;
                &:hover{
                    color: $c-primary;
                }
            }
        }
    }
    
    img{
        max-width: 160px;
        max-height: 82px;
    }
    .calculator__item{
        display: flex;
        align-items: center;
        .select{
            width: 100%;
            max-width: 264px;
            span{
                font-weight: 400;
            }
            &.quant, &.mount{
                max-width: fit-content;
            }
        }
        .select__body{
            span{
                font-weight: 400;
            }
        }
        &:nth-child(5){
            
            p{
                color: $c-white;
            }
        }
        .price{
            color: $c-white;
        }
        span{
            color: $c-main;
            display: flex;
            font-weight: 700;
            margin: 0;
        }
        .select__current{
            font-weight: 400 !important;
            flex: 0 0 94%;
        }
    }
    @include media(950px){
        flex-wrap: wrap;
        &:first-child{
            display: none;
        }
        .calculator__item{
            margin-bottom: rem(30);
            &:first-child{
                flex: 0 0 30% !important;
            }
            &:nth-child(2){
                order: 2;
                flex: 0 0 100% !important;
            }
            &:nth-child(2){
                order: 2;
                flex: 0 0 100% !important;
                .select{
                    max-width: 464px;
                }
            }
            &:nth-child(3){
                order: 3;
            }
            &:nth-child(4){
                order: 4;
            }
            &:nth-child(5){
                order: 1;
                flex: 0 0 50%;
                p{
                    min-height: 82px;
                }
            }
        }
    }
    @include media(550px){
        .calculator__item{
            &:first-child{
                margin-right: 0;
            }
            &:nth-child(2){
                .select{
                    max-width: 264px;
                }
            }
            &:nth-child(3){
                margin-right: rem(64);
            }
            &:nth-child(5){
                position: relative;
                padding-left: rem(45);
                .icon{
                    position: absolute;
                    right: 20px;
                    top: 0;
                }
            }
        }
    }
    @include media(420px){
        .calculator__item{
            margin-right: rem(20);
            &:nth-child(3){
                margin-right: rem(20);
            }
        }
        
    }
    @include media(390px){
        .calculator__item{
            &:first-child{
               img{
                max-width: 120px;
               }
            }
            &:nth-child(5){
                p{
                    min-height: 61.5px;
                }
            }
        }
    }
}
.name-hidden{
    font-weight: 400 !important;
    font-size: rem(16) !important;
    line-height: rem(26) !important;
    display: none !important;
    margin-bottom: rem(12) !important;
    color: #939393 !important;
    @include media(950px){
        display: block !important;
    }
}


.calculator__buttonBlock{
    display: flex;
    flex-direction: column;
    margin-left: rem(16);
    button{
        background: transparent;
        border: 0;
        font-size: rem(34);
        color: $c-main-lighten;
        cursor: pointer;
        height: 34px;
    }
}
.calculator__addButton{
    display: flex;
    button{
        width: 100%;
        font-weight: 700;
        background: transparent;
        border-radius: 36px;
        padding: rem(22);
        color: $c-white;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition-primary;
        .icon{
            margin-right: rem(21);
        }
        &:hover{
            color: $c-primary;
        }
        &:disabled{
            border-color: $c-secondary-gray;
            &:hover{
                background: transparent;
            }
        }
    }
}
.calc{
    display: none;
    &.active{
        display: flex;
    }
}
.calculator__fake-radio{
    @include flex-center();
    width: 24px;
    height: 24px;
    min-width: 24px;
    border: 2px solid $c-primary;
    border-radius: 50%;
    margin-right: rem(24);
    span{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $gradient;
        opacity: 0;
        transition: $transition-primary;
    }
}
.calculator__delivery{
    display: flex;
    margin-top: rem(42);
    label{
        display: flex;
        margin-left: rem(40);
        p{
            span{
                font-weight: 700;
            }
        }
        input{
            display: none;
            &:checked + .calculator__rate{
                .calculator__fake-radio{
                    span{
                        opacity: 1;
                    }
                }
                p{
                    color: $c-white;
                }
            }
            
        }
    }
    @include media($sm){
        flex-wrap: wrap;
        label{
            margin-bottom: rem(20);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.calculator__rate{
    display: flex;
    p{
        color: $c-main-lighten;
        transition: $transition-primary;
    }
}
.calculator__sum{
    display: flex;
    align-items: center;
    //margin-top: rem(43);
    p{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        color: $c-white;
        span{
            font-weight: 700;
        }
    }
    .btn{
        margin-left: rem(48);
        margin-top: rem(43);
    }
    @include media($smd){
        .btn{
            margin-left: rem(24);
        }
    }
    @include media(660px){
        flex-wrap: wrap;    
    }
}
.mainBottom{
    .select{
        border: 0 !important;
    }
    .calculator__sum{
        @include media($sm){
            flex-direction: column;
            align-items: flex-end;
        }
     
    }
    .calculator__structure{
        align-items: stretch;
    }
}

.calculator__structure{
    display: flex;
    align-items: center;
    margin-top: rem(43);
    div{
        padding: rem(12) rem(28);
        border-top: 2px solid #939393;
        border-bottom: 2px solid #939393;
        border-left: 2px solid #939393;
        &:last-child{
            border-right: 2px solid #939393;
        }
        &:nth-child(2){
            position: relative;
            .after, .before{
                position: absolute;
                top: 50%;
                width: 24px;
                height: 24px;
                max-width: 24px;
                max-height: 24px;
                background: $c-white;
                border-radius: 50%;
                padding: 0;
                border: 0;
                @include flex-center();
                .icon{
                    font-size: 9px;
                    margin-right: 1px;
                    color: $c-main;
                }
            }
            .after{
                left: 0;
                transform: translate(-12px, -50%);
            }
            .before{
                right: 0;
                transform: translate(12px, -50%);
            }
        }
        
    }
    
}
.modal, .modal__body{
    .calculator__structure{
        div{
            border-top: 2px solid $c-white;
            border-bottom: 2px solid $c-white;
            border-left: 2px solid $c-white; 
            &:last-child{
                border-right: 2px solid $c-white;
            }
        }
    }
    .calculator__fake-radio{
        border: 2px solid $c-main;
        span{
            background: $c-main;
        }
    }
    .calculator__delivery label input:checked + .calculator__rate p{
        color: $c-main;
    }
}
.calculator__bottom{
    @include media($lg){
        flex: 0 0 100%;
        max-width: 100%;
    }
    .calculator-inner__price{
        color: $c-white;
    }
}
.calculator__img{
    display: none;
    &.active{
        display: block;
    }
}
.otherDelivery{
    display: none !important;
    &.active{
        display: flex !important;
    }
}
.calculator__price{
    margin-left: rem(48);
    margin-top: rem(43);
    span{
        font-size: rem(24);
        line-height: rem(32);
    }
}