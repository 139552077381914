.decision{
    @include media($lg){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.decision__imgBlock{
    position: relative;
    //padding-right: 64px;
    picture{
        position: absolute;
        &:nth-child(1){
            position: relative;
            width: 472px;
            left: 0;
            top: 0;
            z-index: 1;
        }
        &:nth-child(2){
            right: 40px;
            bottom: 0;
            z-index: 2;
        }
        &:nth-child(3){
            left: 50%;
            top: 50%;
            transform: translate(-80px,-50%);
            z-index: 3;
        }
    }
    @include media($lg){
        margin-bottom: rem(40);
        picture{
            &:nth-child(2){
                right: 40%;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                left: 24%;
                top: 50%;
                transform: translate(-0px,-50%);
                z-index: 3;
            } 
        }
    }
    @include media($md){
        picture{
            &:nth-child(2){
                right: 24%;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                left: 30%;
                top: 50%;
                transform: translate(-0px,-50%);
                z-index: 3;
            } 
        }
    }
    @include media(720px){
        picture{
            &:nth-child(2){
                right: 18%;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                left: 34%;
                top: 50%;
                transform: translate(-0px,-50%);
                z-index: 3;
            } 
        }
    }
    @include media(690px){
        picture{
            &:nth-child(2){
                left: 50px;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                left: 200px;
                top: 50%;
                transform: translate(-0px,-50%);
                z-index: 3;
            } 
        }
    }
    @include media($sm){
        picture{
            &:nth-child(1){
              width: 60%;  
            }
            &:nth-child(2){
                left: 50px;
                width: 70%;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                width: 40%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                z-index: 3;
            } 
        }
    }
    @include media($xs){
        picture{
            &:nth-child(1){
              width: 60%;  
            }
            &:nth-child(2){
                left: 30px;
                width: 70%;
                bottom: -40px;
                z-index: 2;
            }
            &:nth-child(3){
                width: 40%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                z-index: 3;
            } 
        }
    }
}
.decision__content{
    padding-bottom: rem(40);
}
.decision__item{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    margin-top: rem(40);
    .icon{
        font-size: rem(40);
        margin-bottom: rem(16);
        fill: $icon-fill;
    }

}
.decision__buttonBlock{
    .btn{
        margin-right: rem(40);
        &:last-child{
            margin-right: 0;
        }
    }
    @include media($xs){
        .btn{
            margin-right: rem(20);
            padding: rem(13) rem(20);
        }
    }
}