.filter{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: rem(24) 0;
    margin-bottom: rem(48);
    border-top: 1px solid $c-secondary-gray;
    border-bottom: 1px solid $c-secondary-gray;
}
.filter__item{
    flex: 0 0 calc(33.333% - 48px);
    margin-right: rem(48);
    max-width: 264px;
    .select{
        border: 1px solid $c-secondary-gray;
        border-radius: 32px;
        .select__item{
            span{
                transition: $transition-primary;
                &:hover{
                    color: $c-main-lighten;
                }
            }
        }
    }
    @include media($smd){
        max-width: 100%;
        flex: 0 0 100%;
        margin-right:0;
        margin-bottom: rem(48);
    }
}