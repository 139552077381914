.select {
	position: relative;
    position: relative;
    transition: 0.3s;
    &.is-active{
        .select__body{
            opacity: 1;
            visibility: visible;
        }
        .select__header{
            border-radius: 30px 30px 0 0;
            .icon{
                transform: translate(-50%, -50%) rotate(-180deg);
            }
        }
    }
}


.select__header {
	display: flex;
    min-height: 54px;
    padding: rem(19) rem(24);
    border-radius: 32px;
    background: $c-white;
    transition: $transition-primary;
    &.select__header-s{
        cursor: pointer;
    }
    .icon{
        position: absolute;
        font-size: 14px;
        left: 90%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: $transition-primary;
        color: $c-main;
    }
    
   
}
.select__current {
    display: flex;
    align-items: center;
}
.select__icon {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	height: 40px;
	margin-left: auto;
	text-align: center;
	width: 40px;
}

.select__body {
    background: #fff;
    z-index: 9999;
	opacity: 0;
    visibility: hidden;
	position: absolute;
    border-radius: 32px;
    min-height: 54px;
    border-radius: 0 0 32px 32px;
    border-top: 0;
    transition: $transition-primary;
    width: 100%;
}
.select__item {
	cursor: pointer;
    padding: rem(10) rem(20);
    width: 100%;
    display: flex;
    input{
        display: none;
        position: absolute;
    }
    span{
        flex: 0 0 100% !important;
    }
}
