.selection{
    overflow: hidden;
    .h2{
        color: $c-white;
    }
    .btn{
        margin-top: rem(48);
        box-shadow: 4px 8px 48px rgba(243, 230, 150, 0.24);
    }
    .last-word{
        display: block;
        span{
            color: $c-primary;
        }
    }
    @include media($md){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
            .selection__circle{
                margin: rem(100) 0;
            }
        }
    }
}
.selection__text{
    display: flex;
    align-items: flex-start;
    p{
        color: $c-white;
        font-size: rem(24);
        font-weight: 700;
        line-height: rem(32);
    }
    img{
        margin-top: 8px;
        margin-right: 20px;
    }
}
.selection__imgBlock{
    position: relative;
     .circle{
        .circle__el{
            width: 400px;
            height: 400px;
        }
         @include media($md){ 
           
             left: 21%;
         }
         @include media(650px){ 
            left: 27%;
         }
         @include media(580px){ 
            left: 24%;
         }
         @include media($xs){
            left: 40%;
            .circle__el{
                width: 300px;
                height: 300px;
                left: -1%;
            }
         }
         @include media(330px){
            left: 37%;
         }
     }
    .button-border{
        width: 70%;
    }
    @include flex-center();
    .button-border{
        z-index: -2;
    }
    @include media($lg){
        padding-left: 30px;
        .selection__circle{
            margin: rem(20) 0;
        }
        
    }
    @include media($md){
        justify-content: start;
        margin-left: rem(100);
        .button-border{
            left: -14%;
            height: 100%;
            width: 70%;
        }
    }
    @include media(650px){
        .button-border{
            left: -10%;
            
        }
    }
    @include media($sm){
        .button-border{
            left: -12%;
            
        }
    }
    @include media(520px){
        .button-border{
            width: 80%;
            left: -14%;
        }
    }
    
    @include media($xs){
        margin-left: rem(60);
    }
    @include media(360px){
        .button-border{
            //width: 80%;
            left: -12%;
        }
    }
    @include media(350px){
        .button-border{
            width: 90%;
            left: -14%;
        }
    }
}
.selection__circle{
    width: 264px;
    height: 264px;
    border-radius: 50%;
    background: $c-white;
    position: relative;
    margin: rem(60) 0;
    @include media($sm){
       width: 200px;
       height: 200px; 
    }
    @include media($xs){
        width: 150px;
       height: 150px; 
    }
}
.selection__center{
    width: 100%;
    height: 100%;
    @include flex-center();
    span{
        position: absolute;
       // background: linear-gradient(45deg, $c-primary 23%, $c-white 76%, $c-white);
        //-webkit-background-clip: text;
       // -webkit-text-fill-color: transparent;
        color: $c-primary;
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
    }
    picture{
        // max-width: 60%;
        // max-height: 60%;
    }
}
.selection__absolute{
    position: absolute;
    max-width: 231px;
    max-height: 137px;
    z-index: -1;
    img{
        max-width: 100%;
        max-height: 100%;
    }
    @include media($sm){
        max-width: 180px;
    }
    @include media($xs){
        max-width: 120px;
    }
}
.selection__left-top{
    left: -50%;
    top: -20%;
    @include media($lg){
        left: -44%;
    }
}
.selection__left-bottom{
    left: -50%;
    bottom: -20%;
    @include media($lg){
        left: -44%;
    }
}
.selection__right-top{
    right: -50%;
    top: -20%;
    @include media($lg){
        right: -44%;
    }
}
.selection__right-bottom{
    right: -50%;
    bottom: -20%;
    @include media($lg){
        right: -44%;
    }
}