.general-slide{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: screen;
    z-index: 10;
    //height: 100vh;
    position: relative;
    padding-top: rem(150);
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0f0f0f;
        opacity: 0.9;
        z-index: -1;
        mix-blend-mode: hard-light;
    }
    @include media($lg){
        padding-top: rem(50);
    }
}
.general-slide__content{
    .h1{
        //font-family: "WorkSans", arial, sans-serif;
        line-height: rem(56);
        font-weight: 700;
        color: $c-white;
        font-size: rem(48);
        margin-bottom: rem(48);
    }
    .h2{
        font-weight: 700;
        font-size: rem(36);
        line-height: rem(44);  
        color: $c-primary;
        margin-bottom: rem(10);
    }
    .btn{
        margin-top: rem(50);
        box-shadow: 4px 8px 48px rgba(243, 230, 150, 0.24);
    }
    @include media($lg){
        flex: 0 0 70%;
        max-width: 70%;
    }
    @include media($sm){
        flex: 0 0 90%;
        max-width: 90%;
    }
    @include media($xs){
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.general-slide__advantages{
    *{
        color: $c-white;
    }
    .icon{
        display: block;
        font-size: rem(40);
        margin-bottom: rem(16);
    }
    .advantages__list{
        margin-right: rem(32);
        &:last-child{
            margin-right: 0;
        }
    }
}
.general-slide__imgBlock{
    @include flex-center();
    position: relative;
    .circle{
        z-index: -1;
        @include media($lg){
           // left: -10%;
            .circle__el{
                width: 400px;
                height: 400px;
                margin-left: -20%;
            }
        }
        @include media($smd){
            .circle__el{
                width: 350px;
                height: 350px;
            }
        }
        @include media($sm){
            .circle__el{
                width: 300px;
                height: 300px;
            }
        }
        @include media($xs){
            .circle__el{
                width: 250px;
                height: 250px;
            }
        }
    }
    @include media($lg){
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: start;
        margin-top: rem(40);
        .button-border{
            width: 45%;
            margin-left: rem(100);
        }
        img{
            margin: rem(30);
           
        }
    }
    @include media($smd){
        img{
            margin: rem(10);
        }
        .button-border{
            width: 350px;
        }
    }
    @include media($sm){
        .button-border{
            width: 320px;
            margin-left: rem(80);
        }
    }
    @include media($xs){
        img{
            margin: rem(10);
        }
        .button-border{
            width: 250px;
            margin-left: rem(50);
        }
    }
}
.general-slide__bottom{
    @include flex-center();
    padding: 0;
    margin-top: rem(100);
    position: relative;
}
.general-slide__item{
    display: flex;
    flex: 0 0 33.333%;
    justify-content: space-between;
    align-items: center;
    padding: rem(40);
    border-left: 1px solid rgba($c-white, 0.16);
    border-top: 1px solid rgba($c-white, 0.16);
    max-height: 113px;
    height: 100%;
    transition: $transition-primary;
    &:last-child{
        border-right: 1px solid rgba($c-white, 0.16);
    }
    span{
        color: $c-white;
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
        display: block;
        max-width: 175px;
    }
    .icon{
        color: $c-primary;
        font-size: rem(15);
        min-width: 15px;
        margin-left: rem(15);
    }
    &:hover{
        background: $c-white;
        span{
            color: $c-main;
        }
        .icon{
            color: $c-primary;
        }
    }
    @include media($lg){
        padding: rem(20);
        span{
            max-width: 100%;
        }
    }
    @include media($sm){
        padding: rem(15);
        span{
            font-size: rem(16);
            line-height: rem(22);
        }
        
    }
    @include media($xs){
        padding: rem(2);
        .icon{
            margin-left: 5px;
            font-size: 10px;
        }
        span{
            font-size: rem(15);
        }
    }
}


