.section-spacer {
  padding: rem(80) 0;
  @include phone() {
    padding: rem(50) 0;
  }
}
.embed-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
  }
}