.features__contetn{
    display: flex;
    flex-wrap: wrap;
}
.features__item{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    &:last-child{
        padding-left: 20px;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    @include media($sm){
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: rem(48);
        &:last-child{
            padding-left: 0;
        }
    }
}