.tab__panel{
    display: flex;
    margin-bottom: rem(48);
}
.tab__swich{
    flex: 1 1 auto;
    border-bottom: 1px solid $c-secondary-gray;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: $transition-primary;
    span{
        display: block;
        margin-bottom: rem(16);
        color: $c-main-lighten;     
        margin-right: rem(16);
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
    }
    &.active{
        border-bottom: 2px solid $c-primary;
        span{
            color: $c-main;
        }
         .tab__triangle{
            opacity: 1;
            visibility: visible;
         }
    }
    .tab__triangle{
        position: absolute;
        bottom: -10px;
        left: 50%;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        border-top:10px solid $c-primary;
		border-left:10px solid transparent;
		border-right:10px solid transparent;
        transform: translate(-50%, 0%);
        @include flex-center();
        &::before{
            content: '';
            position: absolute;
            top: -11px;
            z-index: 3;
            border-top:8px solid $c-white;
            border-left:8px solid transparent;
            border-right:8px solid transparent;
        }
    }
    @include media(520px){
        span{
            margin-bottom: rem(8);
            margin-right: rem(8);
            font-size: rem(16);
            line-height: rem(22);
        }
    }
    @include media($xs){
        span{
            font-size: rem(14);
        }
    }
}
.tab__content{
    display: none;
    &.active{
        display: block;
    }
}