.faq {
    width: 100%;
    .faq__item {
        border-top: 1px solid $c-secondary-gray;
        border-left: 1px solid $c-secondary-gray;
        border-right: 1px solid $c-secondary-gray;
        padding: rem(28) rem(40);
        &:last-child{
            border-bottom: 1px solid $c-secondary-gray;
        }
    }
    &--application{
      .faq__item {
        &:first-of-type {
          .faq__text {
            display: none;
          }
        }
      }
    }
    p {
      color: $c-main;
      margin: 0;
    }
  
    .faq__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        position: relative;
        cursor: pointer;   
        .icon{
            transition: $transition-primary;
            width: 11px;
            height: 6px;
            font-size: 11px;
        }
        span{
            display: block;
            font-weight: 700;
            font-size: rem(24);
            line-height: rem(32);
            max-width: 400px;
        }
        &.active{
            .icon{
                transform: rotate(180deg);
            }
        }
    }
    .faq__text {
      padding: rem(20);
      display: none;
    }
    @include media($md){
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.faq-double{
  .col-6{
    @include media($smd){
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: rem(48);
    }
  }
}
