.work__content{
    display: flex;
    flex-wrap: wrap;
}
.work__item{
    flex: 0 0 calc(33.333% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(48);
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .work__view{
        z-index: 2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        .icon{
            color: $c-main;
        }
    }
    &:hover{
        .work__view{
            opacity: 1;
            visibility: visible;
            
        }
    }
}
.OurWorkSlider{
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    .swiper-slide {
      //background: red;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    height: calc((90% - 48px) / 2) !important;
    .work__view{
        z-index: 2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        .icon{
            color: $c-main;
        }
    }
    &:hover{
        .work__view{
            opacity: 1;
            visibility: visible;
            
        }
    }
    @include media(500px){
        height: calc((86% - 30px) / 2) !important;
    }
    @include media(400px){
        height: 100% !important;
        min-height: 200px;
    }
    }
    .swiper-pagination{
        bottom: 0;
        @include media(500px){
            bottom: 10px;
        }
    }
}
.none{
    opacity: 0;
    visibility: hidden;
}