.delivery{
    @include media($md){
        .col-5, .col-7{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.delivery__content{
    display: flex;
    flex-wrap: wrap;
}
.delivery__item{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    span{
        font-weight: 700;
    }
    @include media($xs){
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: rem(48);
    }
}