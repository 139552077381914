.individual{
    .h2{
        display: block;
        color: $c-white;
    }
    .btn{
        margin-top: rem(48);
    }
    .selection__text{
        p{
            font-weight: 700;
            font-size: rem(24);
            line-height: rem(32);
        }
    }
    @include media($smd){
        .col-6{
           flex: 0 0 100%;
            max-width: 100%; 
        }
    }
}
.individual__imgBlock{
    position: relative;
    img{
        margin: 40px 0;
        @include media($smd){
            max-width: 570px;
        }
        @include media($sm){
            max-width: 100%;
        }
    }
    
    @include media($lg){
        .circle{
               top: 40%;
        }
        .individual__icon{
            top: 20%;
        }
    }
    @include media($md){
        .circle{
            top: 30%;
            .circle__el{
                height: 300px;
                width: 300px;
            }
        }
        .individual__icon{
            top: 14%;
        }
    }
    @include media($smd){
        .circle{
           
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .circle__el{
                height: 450px;
                width: 450px;
            }
        }
        .individual__icon{
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
        }
    }
    @include media($sm){
        .circle{
            .circle__el{
                height: 400px;
                width: 400px;
            }
        }
    }
    @include media(500px){
        .circle{
            .circle__el{
                height: 300px;
                width: 300px;
            }
        }
    }
    @include media(400px){
        .circle{
            .circle__el{
                height: 250px;
                width: 250px;
            }
        }
    }
    
}
.individual__icon{
    position: absolute;
    left: 47%;
    top: 32%;
    transform: translate(-50%, 50%);
    width: 88px;
    height: 88px;
    background: transparent;
    border: 2px solid $c-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
        font-size: rem(40);
        color: $c-white;
    }
}