.cabin{
    .calculator__structure{
        background: $c-block;
        margin-right: 0;
        div{
            flex: 1 1 auto;
            p{
                display: flex;
                flex-direction: column;
                span{
                    font-weight: 700;
                }
            }
        }
       
    }
    .calculator__sum{
        justify-content: flex-end;
        p{
            color: $c-main;
        }
    }
    @include media(1248px){
        .col-5, .col-7{
            flex: 0 0 100%;
            max-width: 100%;
            .thumb{
                max-height: 102px;
                .swiper-slide{
                    max-height: 102px;
                    img{
                        max-width: 70%;
                    }
                }
            }
        }
    }
    @include media($md){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .cabin__specifications{
            margin-bottom: rem(48);
        }
        .cabin__padding{
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    @include media($xs){
        .col-9{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .close{
            right: 20px;
            top: 20px;
        }
    }
}
.cabin__content{
    display: flex;
    flex-wrap: wrap;
}
.cabin__info{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    &:last-child{
        margin-right: 0;
        flex: 0 0 50%;
    }
    .title{
        margin-bottom: rem(10);
        display: block;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(26);
    }
    ul{
        li{
            margin-bottom: rem(18);
        }
    }
    .cabin__item{
       display: flex;
       align-items: center;
       margin-bottom: rem(30);
       .icon{
            font-size: rem(32);
            fill: $icon-fill;
            min-width: 32px;
            margin-right: rem(16);
       } 
    }
    @include media(620px){
        flex: 0 0 100%;
        margin-right: 0;
        &.content{
            margin-bottom: rem(48); 
        }
    }
}
.calculator-inner{
    display: flex;
    width: 100%;
    padding: rem(24) 0;
    border-top: 1px solid $c-secondary-gray;
    border-bottom: 1px solid $c-secondary-gray;
    @include media($smd){
        flex-wrap: wrap;
        padding-bottom: 0;
    }
}
.calculator-inner__item{
    margin-right: rem(20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child{
        margin-right: 0;
    }
    p{
        .price{
            display: block;
            font-weight: 700;
        }
    }
    .name{
        display: block;
        color: $c-main;
        margin-bottom: rem(16);
    }
   
    @include media($smd){
        flex: 0 0 auto;
        margin-bottom: rem(24);
    }
    @include media(420px){
        margin-right: 10px;
    }
}
.calculator-inner__row{
    display: flex;
    align-items: center;
    .select{
        border: 1px solid $c-secondary-gray;
        border-radius: 32px;
    }
}
.calculator-inner__price{
    margin-left: rem(40);
}
.cabin__state{
    display: flex;
    flex-wrap: wrap;
    .cabin__item{
        flex: 1 1 auto;
        margin-right: rem(40);
        margin-bottom: rem(40);
        &:last-child{
            margin-right: 0;
            //margin-bottom: 0;
        }
        span{
            display: block;
        }
        .desc{
            color: $c-main-lighten;
        }
    }
}
.cabin__padding{
    padding-top: rem(48);
    margin-top: rem(48);
    border-top: 1px solid $c-secondary-gray;
    padding-left: 25px;
            padding-right: 25px;
}
.cabin__specifications{
    padding: rem(32);
    background: $c-block;
    .title{
        font-size: rem(24);
        line-height: rem(32);
    }
    p{
        span{
            color: $c-main-lighten;
        }
    }
}
.cabin__furniture{
    .title{
        font-size: rem(24);
        line-height: rem(32);
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            margin-bottom: rem(8);
            margin-right: rem(16);
            span{
                color: $c-main-lighten;
            }
        }
    }
}
.cabin__conditions{
    .title{
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(24);
    }
    .subtitle{
        font-weight: 700;
        display: block;
        margin-bottom: rem(16);
    }
    .cabin__content{
        display: flex;
        flex-wrap: wrap;
    }
    .cabin__column{
        flex: 0 0 calc(50% - 48px);
        margin-right: rem(48);
        &:last-child{
            flex: 0 0 50%;
            margin-right: 0;
        }
        ul{
            li{
                margin-bottom: rem(16);
            }
        }
        @include media($sm){
            flex: 0 0 100%;
            margin-right: 0;
            &:last-child{
                flex: 0 0 100%;
            }
        }
    }
}
.modalBottom{
    .calculator__structure{
        @include media($sm){
            flex-direction: column;
            div{
                flex: 1 1 100%;
                width: 100%;
                border-top: 0;
                border-left: 0;
                &:nth-child(2){
                    .after{
                        left: 50%;
                        transform: translate(-10px, -50%);
                        top: 0;
                    }
                    .before{
                        right: 50%;
                        transform: translate(7px, -30%);
                        top: 100%;
                    }
                }
            }
        }
        @include media($smd){
            margin-right: rem(24);
        }
        @include media(660px){
            margin-bottom: rem(24);
        }
        @include media($xs){
            div{
                padding: rem(8) rem(18);
            }
        }
    }
}

.other__slider{
    margin-top: rem(56);
    .sliderOther{
        max-height: none;
        .swiper-wrapper{
            align-items: start;
        }
        .swiper-slide{
            display: block;
            padding: rem(24);
            border-right: 1px solid $c-secondary-gray;
        }
    }
    .slider__desc{
        left: 1px;
    }
}
.sliderOther__content{
    margin-top: rem(34);
    p{
        margin-bottom: 0;
        span{
            color: $c-main-lighten;
        }
    }
}
.sliderOther__price{
    font-weight: 700;
}
.sliderOther__wrapper{
    display: flex;  
    flex-wrap: wrap;
    margin-top: rem(24);
}
.sliderOther__item{
    display: flex;
    align-items: flex-start;
    //flex: 0 0 50%;
    flex: 0 0 calc(50% - 16px);
    margin-right: rem(16); 
    margin-bottom: 16px;
    .icon{
        font-size: rem(24);
        min-width: 24px;
        fill: $icon-fill;
        margin-right: rem(10);
    }
    @include media($xs){
        flex: 0 0 100%;
        margin-right: 0; 
    }
}
.sliderOther-inner{
    .swiper-slide{
        border-right: 0 !important;
    }
    .swiper-paginationd{
        @include flex-center();
        .swiper-pagination-bullet{
            height: 10px;
            width: 10px;
            border: 2px solid $c-secondary-gray;
            background: transparent;
            margin: 0 rem(8);
            opacity: 1;
            &-active{
                background: $c-secondary-gray;
            }
        }
    }
}
.other{
    margin-top: rem(104);
    @include media($sm){
        .col-8, .col-4{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-4{
            justify-content: start;
            margin-top: rem(48);
        }
    }
}
.sale-calc{
    .calculator-inner__item{
        margin-right: rem(32);
        justify-content: start;
        &:last-child{
            margin-right: 0;
        }
    }
    .sale_price{
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(26);
        margin-top: 16px;
    }
}
.sale-bottom{
    .calculator__sum{
        @include media(360px){
            flex-direction: column;
            align-items: end;
            .calculator__price, .btn{
                margin-left: 0;
                margin-bottom: rem(20);
            }
        }
    }
}
.cabin__wrapper{
    @include media($md){
        .col-6{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    @include media($smd){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}