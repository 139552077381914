.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0; 
    background: rgba($c-main, 0.48);
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition:all 0.3s ease;
    &.active{
        opacity: 1;
        visibility: visible;
    }
    body{
        &.overflow-hidden{
            overflow: hidden;
        }
    }
}
.modal__body{
    @include flex-center();
    margin: 0;
    vertical-align: middle;
    width: 100%;
    min-height: 100vh;
    position: relative;
    .close-block{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;
    }
} 
.modal__content{
    background:$c-white;
    padding: rem(45);
    margin-top: rem(60);
    margin-bottom: rem(60);
    //width: 100%;
    //max-width: 1248px;
    position: relative;
    z-index: 3;
    .close{
       position: absolute;
       right: 60px;
       top: 60px;
       color: $c-main-lighten;
       transition: $transition-primary;
       cursor: pointer;
       &:hover{
        color: $c-primary;
       } 
    }
    @include media-min(0) {
        max-width:96%;
      }
    @include media-min($smd) {
        max-width: $tablet;
      }
      @include media-min($md) {
        max-width: $laptop;
      }
      @include media-min(1248px) {
          max-width: 1248px;
      }
      @include media-min($xlg) {
        max-width: $wide;
      }
      
    @include media($middle){
        padding: rem(30);
    }
    @include media($xs){
        padding: rem(26);
      //  max-width:100%;
    }
}