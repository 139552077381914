.footer{
    background: #1d1d1f;
}
.footer__content{
    display: flex;
    flex-wrap: wrap;
    &.first{
        align-items: center;
    }
}
.footer__item{
    &:first-child{
        ul{
            li{
                a{
                    font-weight: 700;
                    font-size: rem(24);
                    line-height: rem(26);
                    color: $c-white;
                }
            }
        }
    }
    flex: 0 0 calc(17.5% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(28);
    ul{
        li{
            
            a{
                color: #939393;
                margin-bottom: rem(20);
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
                &:hover{
                    color: $c-primary;
                }
            }
        }
    }
    .title{
        color: $c-main-lighten;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
        margin-bottom: 20px;
    }
    @include media($lg){
        &:first-child{
            flex: 0 0 calc(50% - 48px);
        }
        flex: 0 0 calc(25% - 48px);
    }
    @include media($smd){
        flex: 0 0 calc(50% - 48px);
    }
    @include media($sm){
        &:first-child{
            flex: 0 0 100%;
            margin-right: 0;
        }
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.footer__info{
    flex: 0 0 27%;
    padding: rem(40);
    margin-bottom: rem(48);
    background: #333335;
    align-self: flex-start;
    @include media($lg){
        flex: 0 0 100%;
    }
    @include media($smd){
        flex: 0 0 50%;
    }
    @include media($sm){
        flex: 0 0 100%;
    }
}
.footer__phone{
    display: flex;
    flex-direction: column;
    a{
        color: $c-white;
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: 8px;
        &:hover{
            color: $c-primary;
        }
    }
}
.footer__call{
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #0B2349;
    display: table;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin: rem(20) 0;
    &:hover{
        background: $c-primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #0B2349;
        display: table;
    }
}
.footer__mail{
    color: $c-main-lighten;
    &:hover{
        color: $c-primary;
    }
}
.footer__whatsapp{
    display: flex;
    align-items: center;
    margin-top: rem(20);
    .icon{
        color: #57B93E;
        font-size: rem(24);
        margin-right: rem(16);
    }
    span{
        font-family: 'Inter', arial, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $c-white;
        transition: $transition-primary;
    }
    &:hover{
        span{
            color: $c-primary;
        }
    }
}
.footer__time{
    color: $c-main-lighten;
    margin-top: rem(12);
}
.footer__logo{
    margin-bottom: rem(20);
    width: 80px;
}