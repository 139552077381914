.construction{
    .tab__content{
        &.active{
            display: flex;
            flex-wrap: wrap;
        }
    }
}
.construction__imgBlock, .construction__info{
    flex: 0 0 50%;
    padding: rem(29) rem(40);
    @include media($md){
        flex: 0 0 100%;
    }
}
.construction__imgBlock{
    background: $c-block;
    @include flex-center();
    img{
        max-width: 100%;
        object-fit: cover;
    }
}
.construction__item{
    display: flex;
    margin-bottom: rem(32);
    .icon{
        font-size: rem(32);
        min-width: 32px;
        fill: $icon-fill;
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.construction__desc{
    margin-left: rem(24);
    .title{
        margin-top: 0;
        margin-bottom: rem(8);
    }
}