.company{
    @include media($md){
        .col-4, .col-8{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}
.company__content{
    display: flex;
    //align-items: center;
    flex-wrap: wrap;
}
.company__item{
    flex: 0 0 25%;
    padding: rem(30);
    padding-top: 0;
    img{
        min-width: 80px;
    }
}