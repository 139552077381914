// .circle {
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0%; 
//   left: 0%;
//   display: flex;
//   align-items: center;
//   align-content: center; 
//   justify-content: center;  
//   z-index: -2;
 
// }

// .circle__el {
//   width: 450px;
//   height: 450px;
//   background: transparent;
//   border: 3px solid $c-white;
//   border-radius: 50%;
//   -webkit-animation: pulsar 2s 0s linear infinite;
//   animation: pulsar 2s 0s linear infinite;
// }

// .circle__el_two {
//   -webkit-animation: pulsar 2s .5s linear infinite;
//   animation: pulsar 2s .5s linear infinite;
// }

// .circle__el_three {
//   -webkit-animation: pulsar 2s 1s linear infinite;
//   animation: pulsar 2s 1s linear infinite;
// }
// .circle__el_foo {
//   -webkit-animation: pulsar 2s 1.5s linear infinite;
//   animation: pulsar 2s 1.5s linear infinite;
// }

// .circle__el:nth-child(1) {
//   -webkit-animation: pulsar 2s .5s linear infinite;
//   animation: pulsar 2s .5s linear infinite;
// }

// .circle__el:nth-child(2) {
//   -webkit-animation: pulsar 2s .5s linear infinite;
//   animation: pulsar 2s .5s linear infinite;
// }

// .circle__el:nth-child(3) {
//   -webkit-animation: pulsar 2s 1s linear infinite;
//   animation: pulsar 2s 1s linear infinite;
// }

// .circle__el:nth-child(4) {
//   -webkit-animation: pulsar 2s 1.5s linear infinite;
//   animation: pulsar 2s 1.5s linear infinite;
// }
//  @keyframes pulsar {
//   0% {
//     transform: scale(0);
//     opacity: 1;
// }
// 75% {
//     opacity: 1;
// }
// 100% {
//     transform: scale(1);
//     opacity: 0;
// }
//  }










// // HTML CSS JSResult Skip Results Iframe
// // @keyframes pulsar {
// //     0% {
// //         transform: scale(0);
// //         opacity: 1;
// //     }
// //     75% {
// //         opacity: 1;
// //     }
// //     100% {
// //         transform: scale(1);
// //         opacity: 0;
// //     }
// // }

// // @-webkit-keyframes pulsar {
// //     0% {
// //         -webkit-transform: scale(0);
// //         opacity: 1;
// //     }
// //     75% {
// //         opacity: 1;
// //     }
// //     100% {
// //         -webkit-transform: scale(1);
// //         opacity: 0;
// //     }
// // }


// @-webkit-keyframes pulsar {
//   0% {
//   transform: scale(0);
//   opacity: 1;
// }
// 75% {
//   opacity: 1;
// }
// 100% {
//   transform: scale(1);
//   opacity: 0;
// }
// }

.circle {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: center;  

}

.circle__el {
  box-sizing: border-box;
  position: absolute;
  //top: 50%;
  //left: 50%;
  border: 3px solid $c-white;
  box-shadow: inset 0 0 3px 0 $c-white;
  border-radius: 50%;
  width: 450px;
  height: 450px;
  //margin-top: -50%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.circle__el:nth-child(1) {
  -webkit-animation: pulsar 2s 0s linear infinite;
  animation: pulsar 2s 0s linear infinite;
}

.circle__el:nth-child(2) {
  -webkit-animation: pulsar 2s .5s linear infinite;
  animation: pulsar 2s .5s linear infinite;
}

.circle__el:nth-child(3) {
  -webkit-animation: pulsar 2s 1s linear infinite;
  animation: pulsar 2s 1s linear infinite;
}

.circle__el:nth-child(4) {
  -webkit-animation: pulsar 2s 1.5s linear infinite;
  animation: pulsar 2s 1.5s linear infinite;
}
@keyframes pulsar {
  0% {
      transform: scale(0);
      opacity: 1;
  }
  75% {
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}