.scroll-top{
    bottom: 50px;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 50px;
    z-index: 9999;
    transition: $transition-primary;
    border-radius: 50%;
    background: $gradient;
    visibility: hidden;
    opacity: 0;
    @include flex-center();
    .icon{
        color: $c-main;
        transform: rotate(-180deg);
    }
    &.active{
        visibility: visible;
        opacity: 1;
    }
}