.order__content{
    display: flex;
    flex-wrap: wrap;
}
.order__scheme{
    flex: 0 0 calc(33.333% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(48);
    img{
        min-width: 153px;
        object-fit: cover;
    }
    @include media(412px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.order__desc{
    p{
        span{
            color: $c-main-lighten;
        }
    }
}
.order__circle{
    width: 56px;
    height: 56px;
    min-width: 56px;
    border-radius: 50%;
    margin-right: rem(24);
    &.last{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $c-secondary-gray;
        .icon{
            color: $c-main-lighten;
            font-size: 14px;
            font-weight: 400;
        }
    }
}
.order__color{
    display: flex;
    align-items: center;
    margin-right: rem(48);
    margin-bottom: rem(48);
    flex: 0 0 calc(20% - 48px);
    &:last-child{
        flex: 0 0 40%;
        margin-right: 0;
    }
    @include media($md){
        flex: 0 0 calc(33.333% - 48px);
        &:last-child{
            flex: 0 0 65%;
        }
    }
    @include media(450px){
        flex: 0 0 calc(50% - 48px);
        &:last-child{
            flex: 0 0 100%;
        }
    }
}
.order__finishing{
    flex: 0 0 calc(25% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(48);
    img{
        max-width: 100%;
        object-fit: cover;
    }
    @include media($lg){
        flex: 0 0 calc(50% - 48px);
    }
    @include media(450px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}