.article{
    @include media($smd){
        .col-6{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}
.article__imgBlock{
    .slider{
        max-height: 100%;
        border: 0;
        position: relative;
        .swiper-slide{
            padding: 0;
        }
        .territory__swich{
            right: 24px;
            left: 24px;
            top: 50%;
            width: 92%;
            transform: translate(0, -50%);
            justify-content: space-between;
            @include media(550px){
                width: 89%;
            }
        }
    }
    @include media($smd){
        max-width: 576px;
        &.first{
            margin-bottom: rem(48);
        }
        &.last{
            margin-top: rem(48);
        }
    }
}
.article__content{
    .btn{
        margin-top: rem(40);
    }
}