.params{
    display: flex;
    flex-wrap: wrap;

}
.params__item{
    display: flex;
    align-items: center;
    flex: 0 0 calc(33.333% - 30px);
    margin-right: rem(30);
    .icon{
        font-size: rem(32);
        min-width: 32px;
        fill: $icon-fill;
        margin-right: rem(16);
    }
    @include media($md){
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: rem(30);
    }
}