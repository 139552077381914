
.advantage__content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.advantage__item{
    display: flex;
    flex-direction: column;
    padding: rem(30);
    flex: 0 0 25%;
    p{
        color: $c-white;
    }
    span{
        font-weight: 700;
        font-size: rem(48);
        line-height: rem(50);
        background: linear-gradient(45deg, $c-primary 23%, $c-white 76%, $c-white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $c-primary;
        display: table;
    }
    @include media($smd){
        flex: 0 0 50%; 
    }
}