.breadcrumbs {
  padding: rem(40) 0 rem(15) 0;
  .breadcrumbs__list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li{
      color: #CED0DA;
      font-weight: 400;
      font-size: rem(15);
      line-height: rem(22);
      a{
        color: $c-main;
        transition: 0.3s;
        &:hover{
          color:$c-main;
        }
      }
    }
    .breadcrumbs__icon{
      fill: rgba(182, 184, 199, 1);
      margin: 0 rem(15);
    }
  }
}
  