.loader {
    --b: 2px;  /* border thickness */
    --n: 0;    /* number of dashes*/
    --g: 0deg; /* gap between dashes*/
    --c: #F4D366;   /* the color */
    width: 80px; /* size */
    height: 80px;
    position: absolute;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(from 0turn at 50% 50%, $c-primary 0%, rgba($c-white, 0.08) 30%);
    -webkit-mask:
      repeating-conic-gradient(rgba($c-white, 0.008) 0deg,
         #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
         rgba($c-white, 0.008)     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
      radial-gradient(farthest-side,rgba($c-white, 0.008) calc(98% - var(--b)),#000 calc(100% - var(--b)));
            mask:
      repeating-conic-gradient(rgba($c-white, 0.008) 0deg,
         #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
         rgba($c-white, 0.008)     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
      radial-gradient(farthest-side,rgba($c-white, 0.008) calc(98% - var(--b)),#000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
            mask-composite: intersect;

  }