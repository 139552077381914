.wrapper {
  padding-right: $offset_one_side;
  padding-left: $offset_one_side;
  @include tablet {
    padding-right: $offset_one_side-tablet !important;
    padding-left: $offset_one_side-tablet !important;
  }

  margin-right: auto;
  margin-left: auto;

  @include media-min($smd) {
    max-width: $tablet;
  }
  @include media-min($md) {
    max-width: $laptop;
  }
  @include media-min($lg) {
    max-width: $desktop;
  }
  @include media-min($xlg) {
    max-width: $wide;
  }
  &--table-overflow{
    @include media(550px) {
      overflow: hidden;
      margin-right: $offset_one_side-tablet !important;
      margin-left: $offset_one_side-tablet !important;
    } 
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$offset_one_side;
  margin-right: -$offset_one_side;
  @include tablet {
    margin-right: -$offset_one_side-tablet;
    margin-left: -$offset_one_side-tablet;
  }
  &--aboutCo{
    flex-direction: row-reverse;
  }
  &--about{
    justify-content: center;
  }

}

.col {
  flex-grow: 1;
  padding: 0 rem(15);
}

.col-12 {
  @include col(12);
}
.col-10 {
  @include col(10);
}
.col-9 {
  @include col(9);
}

.col-sm-9 {
  @include media-min($sm) {
    @include col(9);
  }
}
.col-8 {
  @include col(8);
}
.col-7 {
  @include col(7);
}
.col-lg-7 {
  @include media-min($lg) {
    @include col(7);
  }
}

.col-6 {
  @include col(6);
}

.col-lg-6 {
  @include media-min($lg) {
    @include col(6);
  }
}

.col-md-6 {
  @include media-min($md) {
    @include col(6);
  }
}

.col-smd-6 {
  @include media-min($smd) {
    @include col(6);
  }
}

.col-sm-6 {
  @include media-min($sm) {
    @include col(6);
  }
}

.col-xs-6 {
  @include media-min($xs) {
    @include col(6);
  }
}
.col-5 {
  @include col(5);
}
.col-4 {
  @include col(4);
}

.col-3 {
  @include col(3);
}
.col-2 {
  @include col(2);
}
.col-md-3 {
  @include media-min($md) {
    @include col(3);
  }
}

.shift-left-1 {
  @include shift-left(1);
}

.shift-lg-left-1 {
  @include media-min($lg) {
    @include shift-left(1);
  }
}

.shift-extra-lg-left-1 {
  @include media-min($extra-lg) {
    @include shift-left(1);
  }
}

.shift-right-1 {
  @include shift-right(1);
}

.shift-lg-right-1 {
  @include media-min($lg) {
    @include shift-right(1);
  }
}

.shift-left-6 {
  @include shift-left(6);
}

.shift-left-lg-6 {
  @include media-min($lg) {
    @include shift-left(6);
  }
}

.order-md-2 {
  @include media($md) {
    order: 2;
  }
}

.order-md-1 {
  @include media($md) {
    order: 1;
  }
}

.order-md-3 {
  @include media($md) {
    order: 3;
  }
}
.d-block{
  display: block !important;
}
.rel {
  position: relative;
}

.d-flex {
  display: flex;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.d-flex-wide {
  display: flex;
  width: 100%;
}

.d-flex-reverse{
  display: flex;
  flex-direction: row-reverse;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-bottom {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-md-around {
  @include media-min($md) {
    justify-content: space-around;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.inline {
  display: inline-block !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: rem(10) !important;
}
.mb-20 {
  margin-bottom: rem(20) !important;
}

.mb-30 {
  margin-bottom: rem(30) !important;
}

.mb-xs-30 {
  @include media($xs) {
    margin-bottom: rem(30);
  }
}
.mb-35 {
  margin-bottom: rem(35) !important;
}
.mb-40 {
  margin-bottom: rem(40) !important;
}

.mb-50 {
  margin-bottom: rem(50) !important;
}

.mb-60 {
  margin-bottom: rem(60) !important;
}

.mb-70 {
  margin-bottom: rem(70) !important;
}
.mb-80 {
  margin-bottom: rem(80) !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-10 {
  margin-top: rem(10) !important;
}
.mt-20 {
  margin-top: rem(20) !important;
}

.mt-30 {
  margin-top: rem(30) !important;
}

.mt-40 {
  margin-top: rem(40) !important;
}

.mt-50 {
  margin-top: rem(50) !important;
}

.mt-60 {
  margin-top: rem(60) !important;
}
.mt-70 {
  margin-top: rem(70) !important;
}
.mt-80 {
  margin-top: rem(80) !important;
}

.p-15-0 {
  padding-top: rem(15);
  padding-bottom: rem(15);
}

.pt-20 {
  padding-top: rem(20) !important;
}

.pt-30 {
  padding-top: rem(30) !important;
}

.pt-40 {
  padding-top: rem(40) !important;
}

.pt-48 {
  padding-top: rem(48) !important;
}

.pt-50 {
  padding-top: rem(50) !important;
}

.pt-60 {
  padding-top: rem(60) !important;
}

.pb-20 {
  padding-bottom: rem(20) !important;
}

.pb-30 {
  padding-bottom: rem(30) !important;
}

.pb-40 {
  padding-bottom: rem(40) !important;
}

.pb-50 {
  padding-bottom: rem(50) !important;
}
.pb-48 {
  padding-bottom: rem(48) !important;
}
.pl-48 {
  padding-left: rem(48) !important;
}
.pr-48 {
  padding-right: rem(48) !important;
}
.pb-60 {
  padding-bottom: rem(60) !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100% !important;
}
.overflow-auto{
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
