.scheme{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0f0f0f;
         opacity: 0.8;
         z-index: -1;
        mix-blend-mode: hard-light;
    }
    .h2{
        color: $c-white;
    }
}
.scheme__content{
    display: flex;
    flex-wrap: wrap;
}
.scheme__item{
    flex: 0 0 20%;
    border-right: 1px solid rgba($c-white, 0.08);
    display: flex;
    flex-direction: column;
    padding: rem(23);
    min-height: 272px;
    position: relative;
    &:nth-child(2){
        .loader{
            background: conic-gradient(from 0turn at 50% 50%, $c-primary 20%, rgba($c-white, 0.08) 40%);
        }
    }
    &:nth-child(3){
        .loader{
            background: conic-gradient(from 0turn at 50% 50%, $c-primary 20%, rgba($c-white, 0.08) 60%);
        }
    }
    &:nth-child(4){
        .loader{
            background: conic-gradient(from 0turn at 50% 50%, $c-primary 40%, rgba($c-white, 0.08) 80%);
        }
    }
    &:nth-child(5){
        .loader{
            background: conic-gradient(from 0turn at 50% 50%, $c-primary 100%, rgba($c-white, 0.08) 100%);
        }
    }
    @include media($lg){
        flex: 0 0 50%;
        &:nth-child(2n){
            border-right: 0;
        }  
    }
    @include media($xs){
        flex: 0 0 100%;
        border-right: 0;
        min-height: 220px;
    }
    &:before{
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        background: $c-white;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        transition: $transition-primary;
    }
    &:hover{
        &:before{
            opacity: 0.08;
            visibility: visible;
        }
    }
}
.scheme__text{
    p{
        color: $c-white;
        font-weight: 700;
    }
}
.scheme__circle{
    margin-bottom: rem(40);
    position: relative;
    transition: $transition-primary;
    width: 80px;
    height: 80px;
    z-index: 2;
    @include flex-center();
    &:before {
        content: '';
        width: 76px;
        height: 76px;
        position: absolute;
        z-index: 3;
        background: transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32); 
        color: $c-white; 
        z-index: 4;
    }
    
}
  