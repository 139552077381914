.dark{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -2;
        mix-blend-mode: hard-light;
        background: #242424;
    }
}