.price{
    .tab__content{
        @include media($smd){
            overflow: auto;
        }
    }
}
.price__table{
    min-width: 717px;
}
.price__row{
    display: flex;
    align-items: start;
    padding-top: rem(16);
    padding-bottom: rem(16);
    padding-left: rem(24);
    background: $c-white;
    &:nth-child(odd){
        background: $c-block;
    }
    &--header{
        border-bottom: 1px solid $c-secondary-gray;
        padding-top: 0;
        padding-bottom: rem(8);
        background: $c-white !important;
        .price__item{
            .name{
                color: $c-main-lighten;
            }
        }
    }
    @include media($smd){
        padding-left: rem(12);
    }
}
.price__item{
    display: flex;
    align-items: center;
    flex: 0 0 calc(15% - 24px);
    margin-right: 24px;
    a{
        transition: $transition-primary;
        &:hover{
            color: $c-primary;
            text-decoration: underline;
        }
    }
    &.last-price{
        cursor: pointer;
        position: relative;
        &:hover{
            .price__info{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .name{
        color: $c-main;
    }
    .icon{
        font-size: rem(12);
        min-width: 12px;
        margin-left: rem(10);
    }
    &:first-child{
        flex: 0 0 calc(12% - 24px);
    }
    &:nth-child(5){
        flex: 0 0 calc(14% - 24px);
    }
    @include media($lg){
        .name{
            font-size: rem(12);
        }
    }
    @include media($smd){
        flex: 0 0 calc(15% - 12px);
        margin-right: 12px;
    }
}
.price__info{
    position: absolute;
    right: -20px;
    top: 40px;
    width: max-content;
    background: $c-white;
    padding: rem(32);
    box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.05);
    opacity: 0;
    visibility: hidden;
    transition: $transition-primary;
    z-index: 2;
    .title{
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(26);
        margin-bottom: rem(12);
        display: block;
    }
    ul{
        li{
            line-height: rem(26);
            padding-bottom: 10px;
        }
    }
}