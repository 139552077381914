.form{
    form{
        background: $c-white;
        padding: rem(48);
        padding-right: 16px;
        max-width: 616px;
        .title{
            font-size: rem(24);
            line-height: rem(32);
            width: 80%;
        }
        @include media(450px){
            .title{
                width: 100%;
            }
        }
        @include media(580px){
            max-width: 100%;
            padding-right: 48px;
        }
        
    }
}
.form__wrapper{
    display: flex;
    align-items: center;
    position: relative;
}
.form__content{
    display: flex;
    flex-wrap: wrap;
    .btn{
        margin-top: rem(32);
        margin-right: 32px;
        &:disabled{
            background: transparent;
            color: $c-main-lighten;
            border: 2px solid $c-secondary-gray;
            &::before{
                background: transparent;
            }
            
        }
    }
    @include media(580px){
        .btn{
            margin-right: 0;  
        }
    }
}
.form__item{
    flex: 0 0 calc(50% - 32px);
    margin-right: rem(32);
    margin-bottom: rem(24);
    max-width: calc(50% - 32px);
    span{
        display: block;
        margin-bottom: rem(12);
        color: $c-main;
    }
    @include media($sm){
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: rem(24);
        max-width: 100%;
    }
}
.form__label-text{
    border-radius: rem(32);
    border: 1px solid $c-secondary-gray;
    color: $c-main;
    padding: rem(19) rem(24);
    width: 100%;
}
.form__additionaly{
    .title{
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
    }
    .form__item{
        display: flex;
        align-items: center;
        span{
            margin-bottom: 0;
        }
        .icon{
            font-size: rem(24);
            margin-right: rem(12);
            &.whatsapp{
                color: #66D154;
            }
            &.telegram{
                color: #37AEE1;
            }
        }

    }
}
.form__label-file{
    display: none;
}
.form__file{
    display: flex;
    align-items: center;
    border-radius: rem(36);
    background: $gradient;
    padding: rem(19) rem(24);
    position: relative;
    z-index: 1;
    cursor: pointer;
    max-width: 100%;
    width: 100%;

    &::before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 99%;
        height: 95%;
        background: $c-white;
        border-radius: rem(36);
        z-index: -1;
    }
    span{
        margin-bottom: 0;
        font-weight: 700;
        white-space: nowrap;
            overflow: hidden; 
            text-overflow: ellipsis;
            margin-right: 10px;
           
    }
    .icon{
        font-size: rem(24);
        color: $c-main;
        margin-right: rem(8);
        min-width: 24px;
    }
    .close{
        display: none;
        color: $c-main-lighten;
        font-size: 13px;
        margin-right: 0;
        position: absolute;
        right: 20px;
        min-width: auto;
        &.active{
            display: block;
        }
    }
    @include media($sm){
        &::before{
            width: 99.4%;
            height: 92%;
        }
    }
}
.form__imgBlock{
    position: absolute;
    right: 0;
    width: 70%;
    z-index: -1;
    .form__icon{
        @include flex-center();
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        background: transparent;
        border: 2px solid $c-white;
        border-radius: 50%;
        .icon{
            font-size: 32px;
            color: $c-white;
        }
    }
    .circle__el{
        width: 650px;
        height: 650px;
    }
    img{
        margin: 70px 0;
    }
    @include media($lg){
        width: 58%;
        .circle__el{
            width: 450px;
            height: 450px;
        }
    }
    @include media($md){
        display: none;
    }
}