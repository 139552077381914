.state{
    display: flex;
    flex-wrap: wrap;
}
.state__item{
    flex: 0 0 33.333%;
    @include media($smd){
      flex: 0 0 70%;  
    }
    @include media($sm){
        flex: 0 0 100%;  
      }
}
.state__imgBlock{
    width: 100%;
    position: relative;
    img{
        display: flex;
        width: 100%;
        object-fit: cover;
        max-height: 224px;
    }
}
.state__col{
    @include media($smd){
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.state__content{
    padding: rem(24);
    .title{
        margin-top: 0;
    }
}
.state__info{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: rem(8) rem(24);
    background: $c-white;
    @include flex-center();
    .icon{
        font-size: rem(27);
        color: $c-main;
    }
}