.certificates{
    @include media($md){
        .col-10, .col-2{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-2{
            margin-bottom: rem(20);
        }
    }
}
.certificates__slider{
    .swiper-slide{
        position: relative;
        .slider__viev{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: $transition-primary;
            opacity: 0;
            visibility: hidden;
            background: #fff;
            border-radius: 50%;
            width: 64px;
            height: 64px;
            @include flex-center();
            .icon{
                font-size: rem(18);
            }
        }
        &:hover{
            .slider__viev{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .pagination{
        @include flex-center();
        margin-top: rem(58);
        .swiper-pagination-bullet{
            opacity: 1;
            background: transparent;
            height: 12px;
            width: 12px;
            margin: 0 16px;
            border: 2px solid $c-secondary-gray;
            &-active{
                background: linear-gradient(270deg, #F3E99F 0%, #F5CD57 100%);
                border: 0;
            }
            @include media($xs){
                margin: 0 8px;
            }
        }
    }
}
.certificates__slider-arrow{
    width: 48px;
    height: 48px;
    @include flex-center();
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid $c-primary;
    &--left{
        .icon{
            transform: rotate(180deg);
        }
        margin-right: rem(20);
    }
    &.swiper-button-disabled{
        border: 2px solid $c-secondary-gray;
        .icon{
            color: $c-secondary-gray;
        }
    }
    @include media($sm){
        width: 38px;
        height: 38px;
    }
}

