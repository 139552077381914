.contact{
    @include media($md){
        .col-7, .col-5{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.contact__content{
    display: flex;
    flex-wrap: wrap;
}
.contact__item{
    display: flex;
    align-items: start;
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(32);
    .icon{
        fill: $icon-fill;
        font-size: rem(32);
        min-width: 32px;
        margin-right: rem(24);
        &.whatsapp{
            fill: #57B93E;
        }
    }
    .info{
        line-height: rem(26);
        .name{
            color: $c-main-lighten;
        }
        .data{
            display: block;
            *{
                display: block;
            }
        }
    }
    @include media(521px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.contact__doc{
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    
    .icon{
        color: $c-main;
        font-size: rem(24);
        margin-right: rem(16);
    }
    a{
        font-weight: 700;
    }
}
.contact__form{
    background: $c-block;
    padding: rem(48);
    margin-top: rem(40);
    //margin-left: 15px;
   //margin-right: 15px;
    .btn{
        &:disabled{
            background: transparent;
            color: #939393;
            border: 2px solid #EAEAEA;
            &::before{
                background: transparent;
            }
        }
    }
}
.contact__labelBlock{
    margin-bottom: rem(24);
    input{
        width: 100%;
        padding: rem(19) rem(24);
        border-radius: rem(32);
        border: 1px solid $c-secondary-gray;
    }
    span{
        display: block;
        margin-bottom: rem(12);
    }
}
.mapLink{
    iframe{
        @include media($md){
            max-height: 400px !important;
        }
        @include media($xs){
            max-height: 260px !important;
        }
    }
}