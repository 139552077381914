.territory{
    .territory__img{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include media($sm){
            position: relative;
        }
    }
    .slider{
        min-height: 650px;
        border: 0;
        .swiper-wrapper{
            width: 100%;
            .swiper-slide{
                min-height: 650px;
                position: relative;
                padding: 0;
                @include media($sm){
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        @include media($lg){
            min-height: 550px !important;
            .swiper-slide{
                min-height: 550px !important; 
            }
        }
        @include media($smd){
            min-height: 500px !important;
            .swiper-slide{
                min-height: 500px !important; 
            }
        }
        @include media($sm){
            min-height: auto !important;
            max-height: 100%;
            .swiper-slide{
                min-height: auto !important; 
            }
        }
    }
}
.territory__textBlock{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 46.7%;
    background: $c-white;
    padding: 40px;
    padding-bottom: 0;
    padding-left: 0;
    @include media($lg){
        width: 60%;
    }
    @include media($smd){
        width: 74%;
    }
    @include media($sm){
        width: 100%;
        position: relative;
    }
    @include media($xs){
        padding: 20px;
        padding-bottom: 0;
        padding-left: 0;
    }
}
.territory__swich{
    position: absolute;
    right: 48px;
    top: 55px;
    display: flex;
    z-index: 2;
    .certificates__slider-arrow {
        background: $c-white;
        color: $c-main;
        border: 0;
        &.swiper-button-disabled{
            .icon{
                 color: $c-main-lighten;
            }
        }
    }
    @include media($sm){
        right: 25px;
        top: 25px;
    }
}