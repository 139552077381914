.map{
    @include media($md){
        .col-8{
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: rem(40);
        }
        .col-4{
            max-width: 60%;
            flex: 0 0 60%;
        }
    }
    @include media($sm){
        .col-4{
            max-width: 80%;
            flex: 0 0 80%;
        }
    }
    @include media($xs){
        .col-4{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}
.map__container{
    background-repeat: no-repeat;
    background-size: 100%;
}
.svg-map{
    path{
        stroke: $c-white !important;
    }
}
.map__fixed{
    max-height: 744px;
    position: relative;
    .map__light{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 30%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        transition: $transition-primary;
    }
}
.map__content{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    scrollbar-width: thin;
    scrollbar-color: rgba($c-main, 0.64) rgba($c-main, 0.08);
    &::-webkit-scrollbar {
        height: 12px;
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: rgba($c-main, 0.08);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba($c-main, 0.64);
    }
}
.map__item{
    cursor: pointer;
    margin-bottom: 48px;
    &:last-child{
        z-index: 3;
    }
    &.active{
        .map__text{
            border: 1px solid $c-primary; 
        }
    }
    .sliderMap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 206px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .pagination{
            position: absolute;
            left: 0;
            bottom: 8px;
            width: 100%;
            z-index: 2;
            @include flex-center();
            flex-wrap: wrap;
            padding-left: 32px;
            .swiper-pagination-bullet{
                width: 12px;
                height: 12px;
                background: transparent;
                border: 2px solid $c-white;
                opacity: 1;
                margin: 0;
                margin-right: rem(32);
                margin-bottom: rem(16);
                
                &-active{
                    background: $c-white;
                }
            }
        }
    }
}
.map__text{
    padding: rem(20) rem(32);
    border: 1px solid $c-secondary-gray;
    span{
        font-weight: 700;
    }
}
.point{
    //cursor: pointer;
    position: relative;
    
    path{
        cursor: pointer; 
        stroke: transparent !important;
    }
    &.active{
        path{
            fill: #939393 !important;
        }
    }
    
}