$browser-context: 16;

// grid gutter
$offset_one_side: 24px;
// if adaptive
$offset_one_side-tablet: 15px;

// fonts
$f-heading: "Releway", arial, sans-serif;
$f-text: "Releway", arial, sans-serif;

// colors main
$c-primary: #F4D366;
$c-main: #242424;
$c-main-lighten: #939393; 
$c-secondary-gray: #EAEAEA;
$c-white: #fff;
$c-block: #F6F6F7;
$icon-fill: url(#my-cool-gradient);
$gradient: linear-gradient(270deg, #F3E99F 0%, #F5CD57 100%);


// transitions
$transition-primary: 0.3s;
$transition-secondary: 0.2s;

$imgBase: "/assets/img/";
$imgBg: "/assets/img/backgrounds";

$small: 320px;
$middle: 560px;
$tablet: 760px;
$laptop: 960px;
$desktop: 1200px;
$wide: 1400px;
$large: 1800px;

// breakpoints
$xs: 420px;
$sm: 580px;
$smd: 790px;
$md: 1000px;
$lg: 1200px;
$extra-lg: 1440px;
$xlg: 1925px;
