.nav{
    padding: rem(20);
    border: 1px solid rgba(255, 255, 255, 0.16);
    z-index: 15;
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 17;
        li{
            margin-right: rem(20);
            display: flex;
            align-items: center;
            a{
                color: $c-white;
            }
            &:hover, &.active{
                .link, .icon{
                    color: $c-primary;
                }
            }
            .icon{
                color: $c-white;
                font-size: rem(12);
                margin-left: rem(8);
            }
        }
    }
    .submenu{
        position: relative;
        &:hover{
            .menu, .mega-menu{
                opacity: 1;
                visibility: visible;  
            }
        }
        span{
            color: $c-primary;
        }
        .menu{
            position: absolute;
            flex-direction: column;
            align-items: start;
            background: $c-white;
            width: max-content;
            left: -28%;
            top: 182%;
            box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.05);
            padding: rem(24);
            opacity: 0;
            visibility: hidden;
            transition: $transition-primary;
            ul{
                flex-direction: column;
                align-items: start;
            }
            li{
                margin-bottom: 16px;
                a{
                    color: $c-main;
                }
                &:hover{
                    a{
                        color: $c-primary;
                    }
                }
                &.active{
                    .link{
                        color: $c-primary;
                    }
                }
            }
        }
        .mega-menu{
            display: flex;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            transition: $transition-primary;
            padding: rem(24);
            box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.05);
            left: -28%;
            top: 182%;
            background: $c-white;
            width: 1200px;
            .mega-menu__item{
                flex: 0 0 50%;
                .title{
                    font-weight: 700;
                    display: block;
                    font-size: rem(16);
                    line-height: rem(26);
                    margin-bottom: rem(16);
                    color: $c-main-lighten;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        flex: 0 0 calc(50% - 20px);
                        margin-bottom: 14px;
                        a{
                            color: $c-main;
                            &:hover{
                                color: $c-primary;
                            }
                        }
                        span{
                            color: $c-primary;
                        }
                    }
                }
            }
            .mega-menu__buttonBlock{
                margin-top: rem(8);
                .btn{
                    margin-right: 50px;
                }
            }
        }
    }
    @include media($lg){
        display: none;
    }
}