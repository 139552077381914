.video{
    .btn{
        margin-top: rem(48);
        &:hover{
            color: $c-main;
        }
    }
    @include media($md){
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: rem(48);
    }
}
.video__player{
    position: relative;
    width: 100%;
    height: 322px;
    @include flex-center();
    .circle{
        @include flex-center();
        cursor: pointer;
        .icon{
           fill: transparent;
           font-size: 20px;
           stroke: $c-primary;
        }
    }
    .loader{
        background: conic-gradient(from 0turn at 50% 50%, $c-primary 30%, rgba($c-white, 0.08) 60%);
    }
}
