.about{
    @include media($md){
        .col-4, .col-8, .col-7, .col-5{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .col-8, .col-7{
            margin-top: rem(48);
        }
    }
    .col-7{
        @include media($sm){
            .about__item{
                flex: 0 0 calc(50% - 48px);
                margin-right: rem(48);
            }
        }
        @include media(370px){
            .about__item{
                flex: 0 0 100%;
                 margin-right: 0;
            }
        }
    }
}
.about__content{
    display: flex;
    flex-wrap: wrap;
    &.padding-big{
        padding-left: 120px;
        @include media(1000px){
            padding-left: 15px;
        }
    }
}
.about__item{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(32);
    .title{
        margin-top: rem(17);
        margin-bottom: rem(8);
    }
    .icon{
        font-size: rem(32);
        fill: $icon-fill;
    }
    @include media($sm){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.about__text{
    margin-top: rem(24);
    margin-left: rem(60);
}