.config{
    .h2{
        span{
            font-size: 36px;
            line-height: rem(44);
            span{
                font-size: 24px;
                line-height: 32px;
            }
        }
        @include phone() {
            span{
                font-size: rem(30);
                span{
                    font-size: rem(20);
                }
            }
          }
    }
    .col-6{
        @include media($md){
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}
.config__imgBlock{
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.config__circle{
    @include flex-center();
    background: transparent;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    border: 2px solid $c-primary;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    .plus{
        width: 16px;
        height: 2px;
        border-radius: 10px;
        background: $c-main;
        display: block;
        z-index: 2;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transform: rotate(90deg);
            width: 16px;
            height: 2px;
            border-radius: 10px;
            background: $c-main;
            display: block;
            z-index: 2;
            transition: $transition-primary;
        }
    }
    &.active{
        .config__info{
            opacity: 1;
            visibility: visible;
        }
        .plus{
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(0deg);
                width: 16px;
                height: 2px;
                border-radius: 10px;
                background: $c-main;
                display: block;
                z-index: 2;
            }
        }
    }
    &--insulation{
        transform: matrix(1, 0, 0, 1, -60, -60);
        z-index: 6;
        @include media($sm){
            transform: matrix(1, 0, 0, 1, -20, -20);
        }
    }
    &--insulation-frame{
        transform: matrix(1, 0, 0, 1, -500, -260);
        z-index: 10;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, -400, -210);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, -300, -180);
            .config__info{
                left: -20px;
            }
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, -250, -150);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, -220, -120);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, -200, -100);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, -140, -90);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, -120, -80);
        }
    }
    &--roof{
        transform: matrix(1, 0, 0, 1, 300, -350);
        z-index: 10;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, 250, -300);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, 200, -250);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, 150, -200);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, 110, -160);
            .config__info{
                right: -20px;
            }
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, 90, -130);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, 70, -110);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, 50, -100);
        }
    }
    &--pvx{
        transform: matrix(1, 0, 0, 1, -230, -240);
        z-index: 8;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, -200, -210);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, -150, -150);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, -130, -130);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, -100, -100);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, -80, -80);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, -80, -80);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, -70, -70);
        }
    }
    &--electric{
        transform: matrix(1, 0, 0, 1, 40, -260);
        z-index: 9;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, 10, -210);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, 10, -160);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, 10, -150);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, 10, -120);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, 10, -100);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, 10, -80);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, 10, -70);
        }
    }
    &--wall{
        transform: matrix(1, 0, 0, 1, -400, -20);
        z-index: 5;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, -350, 10);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, -250, 10);
            .config__info{
                left: -20px;
            }
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, -200, 10);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, -160, 10);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, -140, 10);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, -120, 10);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, -110, 10);
        }
    }
    &--window{
        transform: matrix(1, 0, 0, 1, 500, -60);
        z-index: 7;
        .config__info{
            right: -20px;
        }
        @include media($lg){
            transform: matrix(1, 0, 0, 1, 400, -60);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, 320, -60);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, 280, -60);
        }
        @include media(650px){
            transform: matrix(1, 0, 0, 1, 240, -60);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, 210, -60);
        }
        @include media(500px){
            transform: matrix(1, 0, 0, 1, 180, -60);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, 160, -60);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, 140, -60);
            .config__info{
                right: -10px;
            }
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, 130, -60);
        }
    }
    &--flor-first{
        transform: matrix(1, 0, 0, 1, 500, 160);
        z-index: 4;
        .config__info{
            right: -20px;
        }
        @include media($lg){
            transform: matrix(1, 0, 0, 1, 400, 160);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, 320, 100);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, 280, 100);
        }
        @include media(650px){
            transform: matrix(1, 0, 0, 1, 240, 100);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, 210, 100);
        }
        @include media(500px){
            transform: matrix(1, 0, 0, 1, 180, 100);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, 160, 70);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, 140, 70);
            .config__info{
                right: -10px;
            }
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, 130, 70);
        }
    }
    &--flor-second{
        transform: matrix(1, 0, 0, 1, 40, 200);
        z-index: 3;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, 20, 150);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, 20, 120);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, 20, 100);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, 20, 80);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, 0, 50);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, 0, 50);
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, 0, 50);
        }
    }
    &--corrosion{
        transform: matrix(1, 0, 0, 1, -240, 220);
        z-index: 2;
        @include media($lg){
            transform: matrix(1, 0, 0, 1, -200, 180);
        }
        @include media($md){
            transform: matrix(1, 0, 0, 1, -200, 140);
        }
        @include media(725px){
            transform: matrix(1, 0, 0, 1, -160, 130);
        }
        @include media(650px){
            transform: matrix(1, 0, 0, 1, -160, 100);
        }
        @include media(580px){
            transform: matrix(1, 0, 0, 1, -120, 80);
        }
        @include media(450px){
            transform: matrix(1, 0, 0, 1, -100, 60);
        }
        @include media(400px){
            transform: matrix(1, 0, 0, 1, -100, 60);
            .config__info{
                left: -20px;
            }
        }
        @include media(350px){
            transform: matrix(1, 0, 0, 1, -100, 50);
        }
    }
    .icon{
        z-index: 3;
    }
    &::before{
        content: '';
        position: absolute;
        width: 96%;
        height: 96%;
        background: $c-primary;
        border-radius: 50%;
    }
    .config__info{
        position: absolute;
        width: 264px;
        background: $c-white;
        z-index: -1;
        top: 50%;
        padding: rem(25);
        box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.05);
        opacity: 0;
        visibility: hidden;
        transition: $transition-primary;
        span{
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
        }
        
        @include media($xs){
            width: 200px;
            padding: rem(15);
        }
    }
    @include media($smd){
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;
    }
    @include media($sm){
        //transform: matrix(1, 0, 0, 1, -20, -20);
    }
    @include media($xs){
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
    }
}