@mixin font($alias, $name, $weight) {
  @font-face {
    font-family: $alias;
    src: url("../fonts/" + $name + "/" + $name + ".woff2") format("woff2"),
      url("../fonts/" + $name + "/" + $name + ".woff") format("woff"),
      url("../fonts/" + $name + "/" + $name + ".ttf") format("truetype"),
      url("../fonts/" + $name + "/" + $name + ".eot"),
      url("../fonts/" + $name + "/" + $name + ".eot?#iefix")
        format("embedded-opentype");
    font-weight: $weight;
    font-display: swap;
  }
}


@include font("Releway", "Raleway-Regular", 400);
@include font("Releway", "Raleway-Light", 300);
@include font("Releway", "Raleway-Bold", 700);
@include font("Inter", "Inter-Regular", 400);
@include font("WorkSans", "WorkSans-Bold", 700);

