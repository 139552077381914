.news__content{
    display: flex;
    flex-wrap: wrap;
}
.news__item{
    flex: 0 0 33.3333%;
    border: 1px solid $c-secondary-gray;
    @include media($smd){
        flex: 0 0 50%;
    }
    @include media($xs){
        flex: 0 0 100%;
    }
}
.news__info{
    padding: rem(24);
    .title{
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(26);
    }
    .date{
        margin-top: rem(16);
        display: flex;
        align-items: center;
        color: $c-main-lighten;
        .icon{
            margin-right: rem(12);
            font-size: rem(15);
        }
    }
}