p {
  font-size: rem(16);
  margin-bottom: 1rem;
  margin-top: 0;
  color: $c-main;
  letter-spacing: 0.5px;
  line-height: rem(26);
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
  position: relative;
  font-size: rem(16);
  line-height: 1.6;

}
.content {
  ul li {
    padding-left: 20px;
  }
  ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: rem(7);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $c-primary;
  }
}



a {
  color: $c-main;
  transition: color 0.3s ease;
  display: inline-block;
  text-decoration: none;
  &:hover{
    color: $c-primary;
  }
}

h2, .h2 {
  font-size: rem(36);
  line-height: rem(44);
  font-weight: 700;
  margin: 0 0 rem(48) 0;
  letter-spacing: 1px;
  @include phone() {
    font-size: rem(30);
  }
}
.h2{
  display: flex;
  align-items: flex-start;
  img{
    margin-right: rem(16);
    margin-top: rem(16);
    object-fit: contain;
  }
}

ol {
  padding-left: rem(20);
  margin-bottom: rem(30);
}

ol li {
  margin-bottom: rem(10);
}
