.header{
    z-index: 12;
    position: absolute;
    width: 100%;
}
.header__logo{
    @include media(470px){
        max-width: 70px;
    }
    @include media(350px){
        max-width: 50px;
    }
}
.header__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(17) rem(10);
    z-index: 11;
}
.header__content{
    display: flex;
    align-items: center;
}
.logo__de{
    .title{
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(22);
        color: $c-white;
    }
    .subtitle{
        color: $c-white;
    } 
}
.header__item{
    margin-right: rem(40);
    &--logo{
        a{
            display: flex;
            align-items: center;
            .logo__desc{
                &.darks{
                    
                    .title, 
                    .subtitle{
                       color: $c-main; 
                    }
                }
                @include media(550px){
                   // display: none;
                }
            }
            .title{
                font-weight: 700;
                font-size: rem(18);
                line-height: rem(22);
                color: $c-white;
            }
            .subtitle{
                color: $c-white;
            }
        }
    }
    &--adress{
        max-width: 220px;
    }
    &--phone{
        max-width: 130px;
    }
    &--time{
        max-width: 120px;
    }
    &--burger{
        display: none;
    }
    &:last-child{
        margin-right: 0;
    }
    .btn{
        white-space: nowrap;
        padding: rem(9) rem(20) rem(11);
        display: flex;
        align-items: center;
        color: $c-white;
        max-width: 140px;
        .icon{
            font-size: rem(24);
            margin-right: rem(10);
        }
    }
    @include media($lg){
        &--adress, &--time, &--catalog, &--call{
            display: none;
        }
        &--burger{
            display: block;
        }
    }
    @include media(470px){
        margin-right: 20px;
        &--logo{
            a{
                .title{
                    font-size: rem(16);
                }
                .subtitle{
                    font-size: rem(12);
                }
            }
        }
    }
   
    
}
.header__text{
    color: $c-white;

}
.header__whatsapp{
    .icon{
        color: #57B93E;
        font-size: 24px;
    }
    @include media($xs){
        .icon{
            font-size: 18px;
        }
    }
}
.header__phone{
    display: flex;
    flex-direction: column;
    a{
        color: $c-white;
        font-weight: 700;
        white-space: nowrap;
        &:hover{
            color: $c-primary;
        }
    }
    @include media(370px){
        a{
            font-size: 11px;
        } 
    }
}
.header__call{
    color: $c-primary;
    white-space: nowrap;
    font-weight: 700;
    cursor: pointer;
}
.header__burger{
    @include flex-center();
    width: 30px;
    height: 20px;
    position: relative;
    span, &:after, &:before{
        width: 100%;
        height: 2px;
        background: $c-white;
        opacity: 1;
        transition: $transition-primary;
    }
    &:after, &:before{
        content: '';
        position: absolute;
    }
    &:after{
        left: 0;
        top: 0;
    }
    &:before{
        left: 0;
        bottom: 0;
    }
    &.active{
        &:after{
            top: 9px;
            transform: rotate(-45deg);
        }
        &:before{
            top: 9px;
            transform: rotate(45deg);
        }
        span{
            transform: scale(0);
        } 
    }
    .disable{
        display: none;
    }
    @include media(470px){
        width: 24px;
        height: 14px;
    }
}
.scroll{
    opacity: 0;
    visibility: hidden;
    display: block;
    background: $c-white;
    top: -100%;
    transition: 0.5s;
    position: fixed;
    .header__item{
        .btn{
            color: $c-main;
        }
    }
    .header__text{
        color: $c-main;
    }
    .header__phone{
        a{
            color: $c-main;
        }
    }
    .nav{
        border: 1px solid $c-secondary-gray;
        ul{
            li{

                a, .icon{
                    color: $c-main;
                }
            }
        }
    }
    .header__burger{
        span, &:after, &:before{
            background: $c-main;
        }
    }
   
    &.active{
        opacity: 1;
        visibility: visible; 
        top: 0;
    }
    &.start{
        opacity: 1;
        visibility: visible; 
        top: 0;
        margin-bottom: 150px;
    }
}