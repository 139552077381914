.conditions{
    .col-6{
        @include media($md){
            flex: 0 0 100%;
            max-width: 100%;
            &.conditions__imgBlock{
                margin-bottom: rem(48);
            }
        }
    }
}
.conditions__imgBlock{
    display: flex;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media($sm){
        flex-direction: column;
    }
}
.conditions__info{
    position: absolute;
    left: 15px;
    bottom: 0;
    background: $c-white;
    padding: rem(32);
    max-width: 310px;
    .title{
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
        margin-top: 0;
        span{
            color: $c-primary;
        }
    }
    ul{
        li{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    @include media($smd){
        left: 0px;
    }
    @include media($sm){
        position: relative;
        max-width: 100%;
    }
}
.conditions__content{
    display: flex;
    flex-wrap: wrap;
}
.conditions__item{
    flex: 0 0 calc(50% - 7px);
    margin-right: rem(7);
    ul{
        li{
            margin-bottom: rem(17);
        }
    }
    &.last{
        flex: 0 0 100%;
        .last__content{
            display: flex;
            flex-wrap: wrap;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    flex: 0 0 calc(50% - 48px);
                    margin-right: rem(48);
                    &:nth-child(2n){
                        margin-right: 0;
                        flex: 0 0 50%;
                    }
                }
            }
        }
    }
    &:nth-child(1), &:nth-child(2){
       .title{
        margin-top: 0;
       } 
    }
    @include media($xs){
        flex: 0 0 100%;
        margin-right: 0;
        &.last{
            .last__content{
                ul{
                    li{
                        flex: 0 0 100%;
                        margin-right: 0; 
                        &:nth-child(2n){
                            flex: 0 0 100%;
                        }
                    }
                }
            }
        }
    }
}
.conditions__link{
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-top: rem(17);
    .icon{
        color: $c-primary;
        font-size: rem(15);
        margin-left: rem(12);
    }
}

.conditions_advantages{
    display: flex;
    flex-wrap: wrap;
}
.conditions-info{
    @include media($lg){
        .col-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    @include media($md){
        padding-top: rem(32);
    }
}
.conditions_advantages__item{
    display: flex;
    align-items: center;
    flex: 0 0 calc(25% - 48px);
    margin-right: rem(48);
    .icon{
        font-size: rem(32);
        min-width: 32px;
        fill: $icon-fill;
        margin-right: rem(20);
    }
    @include media($md){
        flex: 0 0 calc(50% - 48px);
        margin-bottom: rem(48);
    }
    @include media(395px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.conditions-info__data{
    display: flex;
    flex-wrap: wrap;
    @include media($lg){
        margin-top: rem(48);
    }
}
.conditions-info__item{
    flex: 0 0 calc(50% - 48px);
    margin-right: rem(48);
    margin-bottom: rem(40);
    .title{
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    ul{
        li{
            margin-bottom: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
    }
    @include media(450px){
        flex: 0 0 100%;
        margin-right: 0;
    }
}
.conditions-info__textBlock{
    background: $c-block;
    padding: rem(32);
    .title{
        margin-bottom: rem(16);
        margin-top: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            flex: 0 0 calc(50% - 48px);
            margin-right: rem(48);
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            @include media(450px){
                flex: 0 0 100%;
                margin-right: 0;
                margin-bottom: rem(16);
            }
        }
    }

}
.conditions-about{
  .about__item{
    flex: 0 0 calc(33.333% - 48px);
    @include media(370px){
        flex: 0 0 calc(50% - 48px) !important;
         margin-right: rem(48) !important;
    }
  }  
}
.conditions-info-sale{
    li{
        margin-bottom: 16px;
    }
    .h2{
        margin-bottom: rem(32);
    }
}
.reverse{
    @include media($lg){
        flex-direction: column-reverse;
    }
    .conditions-info__imgBlock{
        margin-bottom: rem(48);
    }
}