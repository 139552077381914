.which__imgBlock{
    img{
        width: 100%;
        object-fit: cover;
    }
    @include media($sm){
        max-width: 100%;
        flex: 0 0 100%; 
    }
}
.which__content{
    h3, h2, h4, h5{
        margin-top: 0;
    }
    ul{
        margin-bottom: rem(40);
        li{
            margin-bottom: rem(16);
        }
    }
    @include media($sm){
        max-width: 100%;
        flex: 0 0 100%; 
        margin-top: rem(48);
    }
}