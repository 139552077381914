@import "functions/vars";

@media only screen and (min-width: 1920px) {
  .container {
    max-width: rem(1200);
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 110%;
  }
}

@media only screen and (max-width: 992px) {
  html {
    font-size: 90%;
  }
}

@media only screen and (max-width: 580px) {
  html {
    font-size: 80%;
  }
}

