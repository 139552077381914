.mobile-menu{
    height: 100vh;
    width: 40%;
    width: 260px;
    background-color: $c-main;
    position: fixed;
    z-index: 15;
    overflow: auto;
    padding: rem(15);
    transition: 0.5s;
    left: -100%;
    top: 0;
    &.active{
        left: 0;
    }
    .header__logo{
        div{
            display: flex;
            *{
                color: $c-white;
            }
        }
        margin-bottom: rem(20);
    }

    .header__info{
        max-width: 100%;
        color: $c-white;
        display: block;
        margin-bottom: rem(20);
    }
}
.mobile-menu__nav{
    margin-top: rem(30);
    margin-bottom: rem(30);
    ul{
        li{
            margin-bottom: rem(10);
            
            &:hover{
                .link{
                    color: $c-primary;
                }
            }
            a{
                color: $c-white;
            }
            span{
                color: $c-primary;
            }
            
        }
        .submenus{
            &.active{
                .link{
                    color: $c-primary;
                }
                .icon{
                    color: $c-primary;
                }
            }
            .row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
            }
            .icon{
                color: $c-white;
                font-size: rem(12);
                transition: $transition-primary;
                &.active{
                    transform: rotate(-90deg);
                }
            }
            .menu{
                //position: absolute;
                padding-top: rem(10);
                padding-left: rem(20);
            }
        }
    }
}

