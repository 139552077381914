.slider{
    max-height: 300px;
    border: 1px solid $c-secondary-gray;
    @include flex-center();
    .swiper-wrapper{
       display: flex;
       align-items: center;
    }
    .swiper-slide{
        @include flex-center();
        padding: 40px;
        img{
            object-fit: cover;
        }
    }
    .swiper-button-prev, .swiper-button-next{
        color: $c-main-lighten;
       &:after{
        font-size: rem(20);
        font-weight: 700;
       }
    }
    .slider__circle{
        transition: $transition-primary;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 64px;
        height: 64px;
        z-index: 2;
        aspect-ratio: 1;
        border-radius: 50%;
        background: conic-gradient($c-secondary-gray, $c-primary);
        mask: repeating-conic-gradient($c-secondary-gray 0 360deg) , $c-secondary-gray 0 calc(360deg);
        
        @include flex-center();
        .icon{
            font-size: 20px;
            stroke:$c-primary;
            fill: $c-white;
            z-index: 4;
        }
        &:before {
            content: '';
            width: 59px;
            height: 59px;
            position: absolute;
            z-index: 3;
            background: $c-white;
            border-radius: 50%;
          }
          &:hover{
            background: $c-primary;
          }
    }
    .slider__desc{
        position: absolute;
        left: 0;
        top: 0;
        width: 138px;
        height: 48px;
        background: $c-white;
        border: 1px solid #66D154;
        z-index: 2;
        @include flex-center();
        span{
            color: #66D154;
        }
    }
    .slider__view{
        z-index: 2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: $c-white;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        @include flex-center();
        opacity: 0;
        visibility: hidden;
        transition: $transition-primary;
        .icon{
            font-size: rem(18);
        }
        
    }
    &:hover{
        .slider__view{
            opacity: 1;
            visibility: visible;
        }
    }
    @include media($xs){
        max-height: 250px;
    }
}
.thumb{
    max-height: 72px;
    margin-top: rem(16);
    .swiper-slide{
        border: 1px solid $c-secondary-gray;
        max-height: 72px;
        padding: 10px;
        @include flex-center();
        &-thumb-active{
            border: 1px solid $c-main-lighten;
        }
    }
    @include media($lg){
        max-height: 102px;
        .swiper-slide{
            max-height: 102px;
            img{
                max-width: 70%;
            }
        }
    }
    @include media($sm){
        .swiper-slide{
            img{
                max-width: 100%;
            }
        }
    }
}