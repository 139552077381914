.popup-form{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    top: 0;
    left: 0; 
    background: rgba($c-main, 0.5);
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition:all 0.3s ease;
    &.active{
        opacity: 1;
        visibility: visible;
    }
}
body{
    &.overflow-hidden{
        overflow: hidden;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            z-index: 1000;
        }
    }
}
.popup-form__body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    vertical-align: middle;
    width: 100%;
    min-height: 100vh;
}
.popup-form__content{
    background: $c-white;
    max-width: 500px;
    min-width: 464px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-left: 30px;
    padding: rem(48);
    .form_close{
        position: absolute;
        right: 30px;
        top: 30px;
        color: #939393;
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            color: $c-primary;
        }
    }
    .title{
        font-size: 24px;
    }
    span{
        display: block;
        margin-bottom: rem(12);
    }
    div{
        margin-bottom: rem(24);
    }
    .btn{
        align-self: baseline;
        &:disabled{
            background: transparent;
            color: $c-main-lighten;
            border: 2px solid $c-secondary-gray;
            &::before{
                background: transparent;
            }
            
        }
    }
    @include tablet {
        margin-right: 15px;
        margin-left: 15px;
    }
    @include media($sm){
        .form_close{
            right: 10px;
            top: 10px;
            font-size: rem(16);
        }
    }
    @include media($sm){
        padding: rem(30);
        min-width: 350px;
        
    }
    @include media($xs){
        padding: rem(20);
        min-width: 300px;
    }
}
.popup-form__icon{
    font-size: 60px;
    color: $c-primary;
}
.popup-contact{
    .popup-form__content{
        padding: 60px 80px;
        @include media($sm){
            padding: 40px 50px;
        }
        @include media($xs){
            padding: 30px 20px;
        }
    }
}
.popup_success{
    text-align: center;
}