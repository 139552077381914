.svg-map{
    z-index: 1;
   
    .svg-map__point{
        fill: $c-main;
        transition: fill .5s ease;
        &:hover{
            fill: $c-main
        }
        &--primary{
            fill: $c-primary;
            &:hover{
                fill: $c-primary;
                
            }
        }
    }
}